import * as React from 'react';

import { Text, BlogImage, BlogLink } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import kidStudiesWithKokolingo from '../../../images/educational-corner/blog22/dijete-uci-uz-kokolingo.png';
import IvanaK from '../../../images/educational-corner/blog22/logopedica-Ivana-Kujundzic.png';
import writingWithKokolingo from '../../../images/educational-corner/blog22/pisanje-uz-Kokolingo.png';
import IvanaS from '../../../images/educational-corner/blog22/slika-logopedice-Ivane.png';

const SpeechTherapistsAndKokolingo = () => {
  return (
    <PageNew
      title="KOKOLINGO - Zašto logopedi vole Kokolingo?"
      description="Kako bismo olakšali barem dio priprema logopedima te 
      omogućili i online vježbanje ako nas neke situacije spriječe za 
      sastanak našeg djeteta s logopedom „uživo“, nastao je Kokolingo."
      headline="I logoepdi se druže s Kokolingom"
    >
      <Text as="p">
        <b>
          Jeste li se ikada zapitali koliko je važna uloga logopeda u razvoju
          vašeg djeteta?
        </b>
      </Text>
      <Text as="p">
        Ako razvoj govora djeteta ide po planu i nemate potrebe za raznim
        intervencijama i korekcijama, možda nikada nećete pomisliti na logopeda.
        No, što ako se pojavi potreba?
      </Text>
      <Text as="p">
        Ako primijetite da je jezično-govorni razvoj usporen, da postoje
        poteškoće u izgovoru glasova, najčešće L, R, S, Z, Š, Ž,C, Č, Ć, DŽ i Đ,
        ako dijete ima neku od komunikacijskih poteškoća ili čak poteškoće
        čitanja, pisanja, računanja ili učenja, u velikoj mjeri pomoć vam može
        pružiti logoped.
      </Text>
      <Text as="p">
        Kako bismo olakšali barem dio priprema logopedima te omogućili i online
        vježbanje ako nas neke situacije spriječe za sastanak našeg djeteta s
        logopedom „uživo“, nastao je <BlogLink>Kokolingo</BlogLink>.
      </Text>
      <BlogImage
        imgSrc={kidStudiesWithKokolingo}
        imgAlt="Slika prikazuje dijete kako uci uz digitalnu logopedsku vjezbenicu Kokolingo"
        imgTitle="Dijete uci uz Kokolingo"
      />
      <Text as="p">
        Mi u Kokolingu od samih početaka surađujemo s velikim brojem logopeda
        koji nam svojim savjetima, zapažanjima i reakcijama djece s kojima rade
        pomažu da budemo bolji.
      </Text>
      <Text as="p">
        Tako <BlogLink>Kokolingo</BlogLink> raste i uči zajedno s potrebama i
        željama djece, a zauzvrat olakšava rad logopedima te djeci učenje i
        napredak postaju zabavniji.
      </Text>
      <BlogImage
        imgSrc={writingWithKokolingo}
        imgAlt="Slika prikazuje pisanje kao primjer vjezbanja uz Kokolingo"
        imgTitle="Pisanje uz Kokolingo"
      />
      <Text as="h2">Zašto logopedi vole Kokolingo?</Text>
      <Text as="p">
        Svoje dojmove o korištenju Kokolinga u radu s djecom s nama je
        podijelila <b>Ivana Šabić, profesorica logopedije</b>:
      </Text>
      <blockquote style={{ paddingLeft: '20px' }}>
        <cite>
          <Text as="p">
            <BlogLink>Kokolingo</BlogLink> je prva hrvatska digitalna vježbenica
            koja je zadovoljila potrebu za praktičnim materijalom kod djece koja
            imaju govorne teškoće, a koje mogu ometati razumljivost i slušanje.
          </Text>
          <Text as="p">
            Dijete uz <BlogLink>Kokolingo</BlogLink> ponavlja riječi s
            naglašenim ciljanim glasom, glas je slušno izrazito uočljiv pa
            predškolci uz <BlogLink>Kokolingo</BlogLink> gotovo spontano usvoje
            izdvajanje prvog fonema u riječi. Dijete koje poznaje slova nakon
            ponovljenog izgovora riječi može zapisati riječi u bilježnicu pa s
            tom aktivnošću govorna terapija postaje zanimljivija i raznolikija.
          </Text>
          <Text as="p">
            Osim što nam vježbenica može pomoći da dijete ovlada pravilnim
            izgovorom, rješavanjem zadataka i ponavljanjem rečenica možemo puno
            saznati o cjelokupnom govorno-jezičnom razvoju.
          </Text>
          <Text as="p">
            Artikulacijske teškoće kod neke djece se protegnu do 1., a često i
            2. razreda osnovne škole, a nerijetko ih prate i teškoće čitanja i
            pisanja, posebno kod pisanja diktata. Napisane riječi ispod slike
            djecu spontano izlažu pisanom materijalu pa lakše povezuju sliku i
            riječ.
          </Text>
          <Text as="p">
            Vježbenicu <BlogLink>Kokolingo</BlogLink> možemo iskoristiti i kod
            starije djece koja imaju teškoće u čitanju. Učenici koji imaju
            teškoće u čitanju čine nelogične stanke, intonacija im je monotona,
            neispravno naglašavaju dijelove riječi ili rečenica ili ne poštuju
            interpunkcijske znakove.
          </Text>
          <Text as="p">
            Provedbom aktivnosti iz prve hrvatske digitalne vježbenice{' '}
            <BlogLink>Kokolingo</BlogLink>, vježbom imitacije, pamćenja i
            ponavljanja, djeca dolaze do urednog govornog razvoja. Govorni
            razvoj otvara putove jezičnom razvoju te na taj način utječe
            pozitivno na kognitivni razvoj.
          </Text>
          <Text as="p">
            Vježbe imaju šest razina s podrazinama. Poželjno je proći sve
            razine, bez preskakanja. Dobro je jednom se vježbom baviti nekoliko
            dana, bez preskakanja i promjena redoslijeda. Ponudili smo niz
            govornih vježbi za rad s teškoćama artikulacije. Sve su sustavno
            raspoređene i spremne za trenutačnu primjenu.
          </Text>
          <Text as="p">
            Ovom vježbenicom roditeljima je omogućen koristan rad kod kuće, a
            mogu je koristiti stručnjaci raznih profila: logopedi, defektolozi,
            odgajatelji, učitelji te svi zajedno mogu djeci pružiti svu potrebnu
            pomoć.
          </Text>
        </cite>
      </blockquote>
      <BlogImage
        imgSrc={IvanaS}
        imgAlt="Slika prikazuje logopedicu Ivanu Sabic"
        imgTitle="Logopedica Ivana Sabic"
      />
      <Text as="h2">Kokolingo su kreirali logopedi i računalni stručnjaci</Text>
      <Text as="p">
        U nekim zemljama u svijetu digitalne logopedske vježbenice redovito se
        koriste već godinama kao uobičajena nadopuna klasičnim logopedskim
        pristupima, a neke uvelike preuzimaju i dominaciju, jer su pristupačne
        većem broju ljudi i skraćuju vrijeme čekanja na logopedske terapije.
      </Text>
      <Text as="p">
        U pripremi Kokolinga sudjelovala je i{' '}
        <b>profesorica logopedije Ivana Kujundžić</b> koja koristi{' '}
        <BlogLink>Kokolingo</BlogLink> od samog izlaska na tržište. Rado nam je
        prenijela svoja iskustva iz prakse:
      </Text>
      <blockquote style={{ paddingLeft: '20px' }}>
        <cite>
          <Text as="p">
            Obzirom da sam sudjelovala u jednom dijelu njene izrade, znala sam u
            samom početku kakve će mi koristi donijeti u svakodnevnom radu.
            Međutim, vježbenica je daleko premašila sva moja očekivanja. Osim
            toga što mi omogućava da sam svakodnevno u trenutku pripravna za rad
            s učenikom, omogućava mi puno više od same korekcije izgovora.
          </Text>
          <Text as="p">
            Svake godine u tretmanima imam sve više prvašića kojima je potreban
            nekakav vid potpore kako bi se što prije mogli uključiti u rad i
            ostvariti zadane ishode kurikuluma.
          </Text>
          <Text as="p">
            <BlogLink>Kokolingo</BlogLink> mi omogućava sustavan rad na zabavan
            način, što je današnjoj generaciji najvažnije. Ma koliko se mi
            trudili upozoravati roditelje na štetnost pretjeranog izlaganja
            ekranima, ono i dalje postoji od najranije dobi. Djeca navikla na
            brzu izmjenu živih slika, boja, zvukova i svih drugih podražaja
            teško prihvaćaju “suhoparan” tretman. I ne samo tretman, već i
            svakodnevnu nastavu. Zbog toga se <BlogLink>Kokolingo</BlogLink>{' '}
            pokazao kao odlična alternativa tj. nadogradnja klasičnom tretmanu.
          </Text>
          <Text as="p">
            Papigica Koko koja se s vremena na vrijeme pojavljuje na ekranu
            bodreći i hvaleći dijete za uložen trud odličan je motivator,
            pogotovo ako znamo da je personalizirana i da ju svako dijete
            uređuje ovisno o bodovima koje je prikupilo za odrađene zadatke.
          </Text>
          <Text as="p">
            Posebnost Kokolinga jest i u liku gusara Srećka, žive osobe koja nas
            vodi kroz vježbe, postupno objašnjavajući svaki korak.
          </Text>
          <Text as="p">
            Osim osnovnih vježbi za artikulaciju, svakodnevno ga koristim i za
            vježbe fonološkog pamćenja i fonološke diskriminacije, zapisivanje,
            čitanje, proširivanje rječnika, pravilan poredak riječi u rečenici,
            formuliranje rečenica od ponuđenih riječi, stvaranje priča,
            razumijevanje, itd.
          </Text>
          <Text as="p">
            Kako u korekciji dislalija, tako <BlogLink>Kokolingo</BlogLink>{' '}
            koristim i u radu s učenicima s:
            <ul>
              <li>intelektualnim teškoćama</li>
              <li>jezičnim i komunikacijskim teškoćama</li>
              <li>ADHD–om</li>
              <li>početnim teškoćama u usvajanju čitanja i pisanja...</li>
            </ul>
          </Text>
          <Text as="p">
            Vjerujem da ću s vremenom otkriti i druge mogućnosti korištenja ove
            zanimljive i inovativne digitalne vježbenice.
          </Text>
        </cite>
      </blockquote>
      <BlogImage
        imgSrc={IvanaK}
        imgAlt="Slika prikazuje logopedicu Ivanu Kujundzic"
        imgTitle="Logopedica Ivana Kujundzic"
      />
      <Text as="p">
        Drago nam je čuti lijepa povratna iskustva. Svakako želimo da svako
        dijete ima priliku čim prije ispraviti poteškoće koje ga mogu ometati u
        svakidašnjem životu.
      </Text>
      <Text as="p">
        <BlogLink>Kokolingo</BlogLink> se neprestano poboljšava, pa vjerujemo da
        će djeci donijeti još mnogo zabavnih i korisnih trenutaka koji će im
        omogućiti brži napredak.
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default SpeechTherapistsAndKokolingo;
